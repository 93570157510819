import React from "react";
import { useMedia, useToggle } from "react-use";
import { Link } from "gatsby";
import styled from "styled-components";

import { useAuth } from "../hooks/useAuth";
import { BavakTheme } from "../themes/BavakTheme";

import LanguageSwitcher from "./LanguageSwitcher";
import MobileMenu from "./MobileMenu";
import MobileMenuIcon from "./MobileMenuIcon";
import getCurrentLocale from "../utils/getCurrentLocale";

import FullLogo from "../svg/full-logo.svg";
import CartHeaderButton from "./CartHeaderButton";

const HeaderContainer = styled.header`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;

  hr {
    border: 1px solid ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.black};
    margin: 0;
    height: 100%;
  }
`;

const LogoContainer = styled.div`
  width: 12rem;
  height: 100%;
  display: flex;

  svg {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 14rem;
  }
`;

const InnerContainer = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }

  @media screen and (min-width: calc(${({ theme }) => theme.maxWidth} + ${({
      theme,
    }) => theme.maxWidthOuterMargin})) {
    padding: 2rem 0;
  }
`;

const RightAlignedContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 0 1rem;
  font-weight: normal;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    gap: 0 2rem;
  }
`;

const Header: React.FC = () => {
  const { isAuthenticated, isVerified } = useAuth();
  const isEnglish = getCurrentLocale() === "en";
  const [isMobileMenuOpen, toggleMobileMenu] = useToggle(false);
  const isBiggerThenMobile = useMedia(
    `(min-width: ${BavakTheme.breakpoints.laptop})`
  );

  return (
    <>
      <HeaderContainer>
        <InnerContainer>
          <Link to={isEnglish ? "/en/" : "/"}>
            <LogoContainer>
              <FullLogo />
            </LogoContainer>
          </Link>
          <RightAlignedContainer>
            {isBiggerThenMobile ? (
              <>
                {isAuthenticated ? (
                  <Link to={isEnglish ? `/app/en/account/` : `/app/account`}>
                    ACCOUNT
                  </Link>
                ) : (
                  <Link to={isEnglish ? `/en/login/` : `/login/`}>LOG IN</Link>
                )}
                <Link to={isEnglish ? `/en/informatie/` : `/informatie/`}>
                  {isEnglish ? "INFORMATION" : "INFORMATIE"}
                </Link>
                <Link to={isEnglish ? `/en/contact/` : `/contact/`}>
                  CONTACT
                </Link>
                {isVerified && <CartHeaderButton />}
                <hr />
                <LanguageSwitcher />
              </>
            ) : (
              <>
                <LanguageSwitcher />
                {isVerified && <CartHeaderButton />}
                <MobileMenuIcon
                  isOpen={isMobileMenuOpen}
                  onClick={toggleMobileMenu}
                />
              </>
            )}
          </RightAlignedContainer>
        </InnerContainer>
      </HeaderContainer>
      <MobileMenu
        isOpen={isMobileMenuOpen}
        toggleMobileMenu={toggleMobileMenu}
      />
    </>
  );
};

export default Header;
