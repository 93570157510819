import create, { State, StateCreator } from "zustand";
import { persist } from "zustand/middleware";
import produce, { Draft } from "immer";

import { User } from "../models";

interface AuthState extends State {
  user: User | undefined;
  token: string | undefined;
  isAuthenticated: boolean;

  setUser: (user: User) => void;
  setToken: (token: string) => void;
  resetStore: () => void;
}

const immer =
  <T extends State>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === "function"
            ? produce(partial as (state: Draft<T>) => T)
            : (partial as T);
        return set(nextState, replace);
      },
      get,
      api
    );

const useAuthStore = create<AuthState>(
  persist(
    immer((set) => ({
      user: undefined,
      token: undefined,
      isAuthenticated: false,
      setUser: (user: User) => set({ user, isAuthenticated: true }),
      setToken: (token: string) => set({ token }),
      resetStore: () =>
        set({ token: undefined, user: undefined, isAuthenticated: false }),
    })),
    {
      name: "AuthStorage", // unique name
      getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export default useAuthStore;
