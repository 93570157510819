import React, { ReactNode } from "react";
import styled from "styled-components";
import BaseButton from "./BaseButton";

const Button = styled(BaseButton)`
  border: 4px solid ${({ theme }) => theme.colors.black};
`;

interface SecondaryButtonProps {
  children: ReactNode;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  children,
  type,
  disabled,
  onClick,
  className,
}) => (
  <Button
    disabled={disabled}
    type={type}
    onClick={onClick}
    className={className}
  >
    {children}
  </Button>
);

export default SecondaryButton;
