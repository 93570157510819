import { DefaultTheme } from "styled-components";

const BavakTheme: DefaultTheme = {
  maxWidth: "1680px",
  maxWidthOuterMargin: "64px",

  colors: {
    black: "#000000",
    lightGrey: "#F6F6F6",
    grey: "#F0F0F0",
    darkGrey: "#C6C6C6",
    white: "#FFFFFF",
    bavakRed: "#E50140",
  },
  breakpoints: {
    tablet: "768px",
    laptop: "1024px",
    desktop: "1440px",
  },
};

export { BavakTheme };
