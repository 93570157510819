import React, { ReactNode } from "react";
import styled from "styled-components";
import BaseButton from "./BaseButton";

const Button = styled(BaseButton)`
  background: ${({ theme }) => theme.colors.bavakRed};
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;
  border-radius: 64px;
`;

interface PrimaryButtonProps {
  children: ReactNode;
  onClick?: (ev: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: "submit" | "reset" | "button";
  className?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  type,
  disabled,
  onClick,
  className,
}) => (
  <Button
    disabled={disabled}
    className={className}
    type={type}
    onClick={onClick}
  >
    {children}
  </Button>
);

export default PrimaryButton;
