import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import { Link as GatsbyLink } from "gatsby";

const BaseLinkStyle = css`
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  align-items: center;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  letter-spacing: 1px;

  &.active {
    color: ${({ theme }) => theme.colors.bavakRed};
  }
`;

const InternalLink = styled(GatsbyLink)`
  ${BaseLinkStyle}
`;

const ExternalLink = styled.a`
  ${BaseLinkStyle}
`;

interface BaseLinkProps {
  children: ReactNode;
  ariaLabel?: string;
  to?: string;
  href?: string;
  className?: string;
}

const BaseLink: React.FC<BaseLinkProps> = ({
  className,
  children,
  ariaLabel,
  to,
  href,
}) => {
  return to ? (
    <InternalLink
      to={to}
      aria-label={ariaLabel}
      partiallyActive
      activeClassName="active"
      className={className}
    >
      {children}
    </InternalLink>
  ) : (
    <ExternalLink
      href={href}
      aria-label={ariaLabel}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {children}
    </ExternalLink>
  );
};

export default BaseLink;
