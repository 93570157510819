import React from "react";
import { Toaster } from "react-hot-toast";
import styled from "styled-components";

const StyledToastContainer = styled(Toaster)`
  &.toast {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    font: inherit;
    border-radius: 0;
    padding: 1rem 2rem;
    width: auto;
  }

  .Toastify__close-button {
    align-self: center;
  }

  .Toastify__toast--success {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }

  .Toastify__toast--error {
    background: ${({ theme }) => theme.colors.bavakRed};
    color: ${({ theme }) => theme.colors.white};
  }
`;

const ToastContainer = () => (
  <StyledToastContainer
    position="top-center"
    containerClassName="toast__container"
    toastOptions={{
      duration: 3000,
      style: {
        border: "4px solid #F0F0F0",
        padding: "16px",
        color: "#000000",
        borderRadius: "0",
      },
    }}
  />
);

export default ToastContainer;
