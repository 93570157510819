import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledH4 = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;
`;

interface H4Props {
  children: ReactNode;
  className?: string;
}

const H4: React.FC<H4Props> = ({ children, className }) => (
  <StyledH4 className={className}>{children}</StyledH4>
);

export default H4;
