import { graphql, useStaticQuery } from "gatsby";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import { H4 } from "./Text";

import getCurrentLocale from "../utils/getCurrentLocale";

const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.colors.black};
`;

const InnerContainer = styled.div`
  padding: 2rem 1rem;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 1px;

  hr {
    margin: 2rem 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }

  @media screen and (min-width: calc(${({ theme }) => theme.maxWidth} + ${({
      theme,
    }) => theme.maxWidthOuterMargin})) {
    padding: 2rem 0;
  }
`;

const SectionsContainer = styled.div`
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
`;

const LinksContainer = styled.div`
  display: grid;
`;

const PoweredBySpan = styled.span`
  color: ${({ theme }) => theme.colors.bavakRed};
  font-weight: bold;
`;

const SectionContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 25%;
    word-wrap: break-word;
  }
`;

const SectionTitle = styled(H4)`
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

interface SectionProps {
  title: string | JSX.Element;
}

const Section: React.FC<SectionProps> = ({ title, children }) => (
  <SectionContainer>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </SectionContainer>
);

const Footer: React.FC = () => {
  const data = useStaticQuery(graphql`
    query AllProductCategorySlugs {
      productCategories: allStrapiProductCategory {
        nodes {
          slug
          englishName
          dutchName
        }
      }
    }
  `);

  const productCategories = data.productCategories.nodes;
  const isEnglish = getCurrentLocale() === "en";

  return (
    <FooterContainer>
      <InnerContainer>
        <SectionsContainer>
          <Section
            title={
              <SectionTitle>
                POWERED BY <PoweredBySpan>BAVAK SECURITY GROUP</PoweredBySpan>
              </SectionTitle>
            }
          />
          {/* <Section title="POWERED BY BAVAK SECURITY GROUP" /> */}
          <Section title={isEnglish ? "Products" : "Producten"}>
            <LinksContainer>
              {productCategories.map((productCategory: any) => {
                const { slug, dutchName, englishName } = productCategory;
                return (
                  <Link
                    key={slug}
                    to={isEnglish ? `/en/${slug}/` : `/${slug}/`}
                  >
                    {isEnglish ? englishName : dutchName}
                  </Link>
                );
              })}
            </LinksContainer>
          </Section>
          <Section title={isEnglish ? "Information" : "Informatie"}>
            <LinksContainer>
              <Link to={isEnglish ? `/en/informatie/` : `/informatie/`}>
                {isEnglish
                  ? "Standards of bullet-resistant constructions"
                  : "Normen kogelwerende constructies"}
              </Link>
            </LinksContainer>
          </Section>
          <Section title="Links">
            <LinksContainer>
              <a
                href={
                  isEnglish
                    ? "https://bavak.com/terms/"
                    : "https://bavak.com/nl/terms/"
                }
                target="_blank"
              >
                {isEnglish ? "Terms" : "Algemene voorwaarden"}
              </a>
              <a
                href={
                  isEnglish
                    ? "https://bavak.com/privacy-statement/"
                    : "https://bavak.com/nl/privacy-statement/"
                }
                target="_blank"
              >
                {isEnglish ? "Privacy Statement" : "Privacyverklaring"}
              </a>
            </LinksContainer>
          </Section>
        </SectionsContainer>
        <hr />© {new Date().getFullYear()} Bavak Security Group
      </InnerContainer>
    </FooterContainer>
  );
};

export default Footer;
