import React from "react";
import styled from "styled-components";

import { SecondaryLink } from "./Links";
import getCurrentLocale from "../utils/getCurrentLocale";

const languages = require("../languages");

const LanguageSwitchLink = styled(SecondaryLink)`
  color: ${({ theme }) => theme.colors.black};

  &.active {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const getSwitchLink = () => {
  if (typeof window === "undefined") return;

  const pathname = window.location.pathname;
  const localeInPathname = pathname.split("/")[1];
  const { langs, defaultLangKey } = languages;

  // If it's a client side page (/app), don't act
  if (pathname.includes("app")) {
    return pathname.includes("en") ? "/app/account/" : "/app/en/account/";
  }

  if (langs.includes(localeInPathname)) {
    return "/" + (pathname.replace(/^\/[^/]+\/?/, "") || "");
  }

  const currentLang = defaultLangKey;
  const otherLang = langs.find((lang: string) => lang !== currentLang);
  const link = "/" + otherLang + pathname;

  return link;
};

const LanguageSwitcher = () => {
  const isEnglish = getCurrentLocale() === "en";

  return (
    <LanguageSwitchLink
      aria-label={`Change language to ${isEnglish ? "Dutch" : "English"}`}
      to={getSwitchLink()}
    >
      {isEnglish ? "NL" : "EN"}
    </LanguageSwitchLink>
  );
};

export default LanguageSwitcher;
