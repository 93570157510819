import create, { State, StateCreator } from "zustand";
import { persist } from "zustand/middleware";
import produce, { Draft } from "immer";

import { CartProduct, Product } from "../models";

interface CartState extends State {
  cartProducts: CartProduct[];
  addProductToCart: (product: Product) => void;
  removeProductFromCart: (productId: string) => void;
  increaseQuantityInCart: (productId: string) => void;
  decreaseQuantityInCart: (productId: string) => void;
  getTotalAmountInCart: () => number;
  getTotalShippingCostsInCart: () => number;
  emptyCart: () => void;
}

const immer =
  <T extends State>(config: StateCreator<T>): StateCreator<T> =>
  (set, get, api) =>
    config(
      (partial, replace) => {
        const nextState =
          typeof partial === "function"
            ? produce(partial as (state: Draft<T>) => T)
            : (partial as T);
        return set(nextState, replace);
      },
      get,
      api
    );

const useCartStore = create<CartState>(
  persist(
    immer((set, get) => ({
      cartProducts: [],

      addProductToCart: (product: Product) =>
        set((state) => {
          const productIndex = state.cartProducts.findIndex(
            (cp) => cp.product.id === product.id
          );

          const productExistsInCart = productIndex !== -1;

          if (productExistsInCart) {
            const index = state.cartProducts.findIndex(
              (cp) => cp.product.id === product.id
            );

            var currentQuantity = state.cartProducts[index].quantity;
            state.cartProducts[index].quantity = currentQuantity + 1;
          } else {
            state.cartProducts.push({ product, quantity: 1 });
          }
        }),

      increaseQuantityInCart: (productId: string) =>
        set((state) => {
          const productIndex = state.cartProducts.findIndex(
            (cp) => cp.product.id === productId
          );

          if (productIndex === -1) return;

          const currentQuantity = state.cartProducts[productIndex].quantity;
          const nextQuantity = currentQuantity + 1;

          state.cartProducts[productIndex].quantity = nextQuantity;
        }),

      decreaseQuantityInCart: (productId: string) =>
        set((state) => {
          const productIndex = state.cartProducts.findIndex(
            (cp) => cp.product.id === productId
          );

          if (productIndex === -1) return;

          const currentQuantity = state.cartProducts[productIndex].quantity;
          const nextQuantity = currentQuantity - 1;

          nextQuantity === 0
            ? (state.cartProducts = state.cartProducts.filter(
                (cp) => cp.product.id !== productId
              ))
            : (state.cartProducts[productIndex].quantity = nextQuantity);
        }),

      removeProductFromCart: (productId: string) =>
        set((state) => {
          state.cartProducts = state.cartProducts.filter(
            (cp) => cp.product.id !== productId
          );
        }),

      getTotalAmountInCart: () =>
        get().cartProducts.reduce(
          (a, b) => a + (b.product.price * b.quantity || 0),
          0
        ),

      getTotalShippingCostsInCart: () =>
        get().cartProducts.reduce(
          (a, b) => a + (b.product.shippingCost * b.quantity || 0),
          0
        ),

      emptyCart: () => set(() => ({ cartProducts: [] })),
    })),
    {
      name: "CartStorage", // unique name
      getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export default useCartStore;
