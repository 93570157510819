import { useState, useEffect } from "react";
import getCurrentLocale from "../utils/getCurrentLocale";

interface UseCurrentLocaleProps {
  currentLocale: "en" | "nl";
  setCurrentLocale: React.Dispatch<React.SetStateAction<"en" | "nl">>;
  isEnglish: boolean;
  isDutch: boolean;
}

export const useCurrentLocale = (): UseCurrentLocaleProps => {
  const pathLocale = getCurrentLocale();
  const [currentLocale, setCurrentLocale] = useState<"en" | "nl">("nl");

  useEffect(() => setCurrentLocale(pathLocale), [pathLocale]);

  return {
    currentLocale,
    setCurrentLocale,
    isDutch: currentLocale === "nl",
    isEnglish: currentLocale === "en",
  };
};
