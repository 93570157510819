import React, { ReactNode } from "react";
import styled from "styled-components";

const StyledH2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 1px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 28px;
    line-height: 40px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 36px;
    line-height: 48px;
  }
`;

interface H2Props {
  children: ReactNode;
  className?: string;
}

const H2: React.FC<H2Props> = ({ children, className }) => (
  <StyledH2 className={className}>{children}</StyledH2>
);

export default H2;
