import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";
import { Link } from "gatsby";

import { H2 } from "./Text";

import { BavakTheme } from "../themes/BavakTheme";
import getCurrentLocale from "../utils/getCurrentLocale";
import { useAuth } from "../hooks/useAuth";
import LanguageSwitcher from "./LanguageSwitcher";

const RemoveScrollContainer = styled(RemoveScroll)`
  height: 100%;
`;

const Container = styled.div`
  height: 100%;
  display: grid;
  align-content: center;
  justify-content: center;
  gap: 2rem;
  text-align: center;
`;

const MobileMenuLink = styled(Link)`
  width: 100%;
  text-align: center;
`;

interface MobileMenuProps {
  isOpen: boolean;
  toggleMobileMenu: () => void;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  toggleMobileMenu,
}) => {
  const isEnglish = getCurrentLocale() === "en";
  const { isAuthenticated, isVerified } = useAuth();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={toggleMobileMenu}
      contentLabel="Mobile Menu"
      style={{
        content: {
          inset: 0,
          border: "none",
          borderRadius: 0,
          backgroundColor: BavakTheme.colors.bavakRed,
          color: BavakTheme.colors.white,
          padding: "1rem",
        },
      }}
    >
      <RemoveScrollContainer>
        <Container>
          {isAuthenticated ? (
            <MobileMenuLink
              onClick={toggleMobileMenu}
              to={isEnglish ? `/app/en/account/` : `/app/account/`}
            >
              <H2>{isEnglish ? "Account" : "Account"}</H2>
            </MobileMenuLink>
          ) : (
            <MobileMenuLink
              onClick={toggleMobileMenu}
              to={isEnglish ? "/en/login/" : "/login/"}
            >
              <H2>{isEnglish ? "Login" : "Inloggen"}</H2>
            </MobileMenuLink>
          )}
          <MobileMenuLink
            onClick={toggleMobileMenu}
            to={isEnglish ? "/en/informatie/" : "/informatie/"}
          >
            <H2>{isEnglish ? "Information" : "Informatie"}</H2>
          </MobileMenuLink>
          <MobileMenuLink
            onClick={toggleMobileMenu}
            to={isEnglish ? "/en/contact/" : "/contact/"}
          >
            <H2>{isEnglish ? "Contact" : "Contact"}</H2>
          </MobileMenuLink>
          {isVerified && (
            <MobileMenuLink
              onClick={toggleMobileMenu}
              to={isEnglish ? "/en/winkelmand/" : "/winkelmand/"}
            >
              <H2>{isEnglish ? "Cart" : "Winkelmand"}</H2>
            </MobileMenuLink>
          )}
        </Container>
      </RemoveScrollContainer>
    </ReactModal>
  );
};

export default MobileMenu;
