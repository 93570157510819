import React from "react";
import styled from "styled-components";

const MobileMenuIconContainer = styled.div`
  display: grid;
  gap: 6px;
  cursor: pointer;
  align-items: center;
  z-index: 999;
`;

interface MobileMenuIconDividerProps {
  isOpen: boolean;
}

const MobileMenuIconDivider = styled.div<MobileMenuIconDividerProps>`
  height: 2px;
  width: 22px;

  background: ${({ isOpen }) =>
    isOpen
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.black};
`;

interface MobileMenuIconProps {
  className?: string;
  isOpen: boolean;
  onClick: () => void;
}

const MobileMenuIcon: React.FC<MobileMenuIconProps> = ({
  className,
  isOpen,
  onClick,
}) => (
  <MobileMenuIconContainer className={className} onClick={onClick}>
    <MobileMenuIconDivider isOpen={isOpen} />
    <MobileMenuIconDivider isOpen={isOpen} />
    <MobileMenuIconDivider isOpen={isOpen} />
  </MobileMenuIconContainer>
);

export default MobileMenuIcon;
