import axios from "axios";

import { User } from "../models";
import useAuthStore from "../stores/useAuthStore";

interface UseAuthProps {
  isAuthenticated: boolean;
  isConfirmed: boolean;
  isVerified: boolean;
  token: string | undefined;
  user: User | undefined;
  register: (
    email: string,
    password: string,
    companyName: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    KVK: string,
    jobTitle: string
  ) => Promise<User>;
  updateUser: (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    jobTitle: string
  ) => Promise<User>;
  signIn: (email: string, password: string) => Promise<User>;
  signOut: () => Promise<void>;
}

export const useAuth = (): UseAuthProps => {
  const { token, setToken, user, setUser, resetStore } = useAuthStore(
    (state) => ({
      token: state.token,
      user: state.user,
      setToken: state.setToken,
      setUser: state.setUser,
      resetStore: state.resetStore,
    })
  );

  const signIn = async (email: string, password: string): Promise<User> => {
    return axios
      .post(`${process.env.GATSBY_CMS_BASE_URL}/auth/local`, {
        identifier: email,
        password: password,
      })
      .then((response) => {
        const { data } = response;
        const { jwt, user } = data as any;

        setToken(jwt);
        setUser(user);

        return user;
      })
      .catch((error) => {
        console.error("An error occurred:", error.response);
        throw error;
      });
  };

  const updateUser = async (
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    jobTitle: string
  ): Promise<User> => {
    return axios
      .put(
        `${process.env.GATSBY_CMS_BASE_URL}/users/${user?.id}`,
        {
          firstName,
          lastName,
          phoneNumber,
          email,
          username: email,
          jobTitle,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response: any) => {
        const { data: updatedUser } = response;
        setUser(updatedUser);
        return updatedUser;
      })
      .catch((error) => {
        console.error("An error occurred:", error.response);
        throw error;
      });
  };

  const register = async (
    email: string,
    password: string,
    companyName: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    KVK: string,
    jobTitle: string
  ): Promise<User> => {
    return axios
      .post(`${process.env.GATSBY_CMS_BASE_URL}/auth/local/register`, {
        username: email,
        email,
        password,
        companyName,
        firstName,
        lastName,
        phoneNumber,
        KVK,
        jobTitle,
      })
      .then((response) => {
        const { data } = response;
        const { jwt, user } = data as any;

        setToken(jwt);
        setUser(user);

        return user;
      })
      .catch((error) => {
        console.error("An error occurred:", error.response);
      });
  };

  const signOut = async () => resetStore();

  return {
    isAuthenticated: !!user,
    isConfirmed: user !== undefined && user.confirmed,
    isVerified: user !== undefined && user.verified,
    token,
    user,
    signIn,
    register,
    signOut,
    updateUser,
  };
};
