import React, { ReactNode } from "react";
import styled from "styled-components";
import BaseLink from "./BaseLink";

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.colors.bavakRed};
  font-size: inherit;
`;

interface SecondaryLinkProps {
  children: ReactNode;
  to?: string;
  href?: string;
  className?: string;
}

const SecondaryLink: React.FC<SecondaryLinkProps> = ({
  className,
  children,
  to,
  href,
}) => (
  <Link to={to} href={href} className={className}>
    {children}
  </Link>
);

export default SecondaryLink;
