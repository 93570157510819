import React from "react";
import styled from "styled-components";

const Button = styled.button`
  display: flex;
  align-items: center;
  font-family: inherit;
  min-height: 48px;
  width: max-content;
  padding: 0 1rem;
  text-align: center;
  border: none;
  margin: 0;
  font-weight: 900;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
  background: inherit;
  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  &:disabled {
    background: ${({ theme }) => theme.colors.black};
  }
`;

interface BaseButtonProps {
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const BaseButton: React.FC<BaseButtonProps> = ({
  children,
  type = "button",
  disabled = false,
  onClick,
  className,
}) => (
  <Button
    disabled={disabled}
    onClick={onClick}
    type={type}
    className={className}
  >
    {children}
  </Button>
);

export default BaseButton;
