import React, { useEffect } from "react";
import styled, {
  createGlobalStyle,
  css,
  ThemeProvider,
} from "styled-components";
import { Reset } from "styled-reset";
import ReactModal from "react-modal";
import { IntlProvider } from "react-intl";

import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";

import Header from "../components/Header";
import Main from "../components/Main";
import Footer from "../components/Footer";
import ToastContainer from "../components/ToastContainer";

import { BavakTheme } from "../themes/BavakTheme";

import englishMessages from "../intl/en";
import dutchMessages from "../intl/nl";

import { useCurrentLocale } from "../hooks/useCurrentLocale";

const GlobalStyle = createGlobalStyle`${css`
  html,
  body {
    padding: 0;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
  }

  html {
    box-sizing: border-box;
    height: -webkit-fill-available;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }

  body {
    font-family: "Inter";
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.75px;
    min-height: -webkit-fill-available;
    overscroll-behavior-y: none;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
    list-style: initial;
    margin: initial;
    padding: 0 0 0 2rem;
  }

  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }

  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 1rem;
  }

  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 1rem;
  }

  li {
    display: list-item;
  }

  // Bug where the image is less then the parent's container height
  .gatsby-image-wrapper-constrained {
    display: block;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    body {
      font-size: 20px;
      line-height: 32px;
    }
  }
`}`;

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MaxWidthContainer = styled.div`
  flex: 1 0 auto; // Makes the footer sticky to the bottom

  @media screen and (min-width: ${({ theme }) => theme.maxWidth}) {
    width: ${({ theme }) => theme.maxWidth};
    margin: 0 auto;
  }

  @media screen and (min-width: calc(${({ theme }) => theme.maxWidth} + ${({
      theme,
    }) => theme.maxWidthOuterMargin})) {
    padding: 0;
  }
`;

const Layout: React.FC = ({ children }) => {
  const { currentLocale } = useCurrentLocale();

  useEffect(() => {
    ReactModal.setAppElement(`#___gatsby`);
  }, []);

  const getLocaleMessages = (locale: string) => {
    switch (locale) {
      case "en":
        return englishMessages;
      default:
        return dutchMessages;
    }
  };

  return (
    <ThemeProvider theme={BavakTheme}>
      <IntlProvider
        messages={getLocaleMessages(currentLocale)}
        locale={currentLocale}
        defaultLocale="nl"
      >
        <Reset />
        <GlobalStyle />
        <LayoutContainer>
          <ToastContainer />
          <Header />
          <MaxWidthContainer>
            <Main>{children}</Main>
          </MaxWidthContainer>
          <Footer />
        </LayoutContainer>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default Layout;
