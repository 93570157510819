const languages = require("../languages");

const getCurrentLocale = (): "en" | "nl" => {
  const locale =
    typeof window !== "undefined" ? window.location.pathname.split("/")[1] : "";

  return languages.langs.includes(locale) ? locale : languages.defaultLangKey;
};

export default getCurrentLocale;
