exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-betaling-geslaagd-en-tsx": () => import("./../../../src/pages/betaling-geslaagd.en.tsx" /* webpackChunkName: "component---src-pages-betaling-geslaagd-en-tsx" */),
  "component---src-pages-betaling-geslaagd-nl-tsx": () => import("./../../../src/pages/betaling-geslaagd.nl.tsx" /* webpackChunkName: "component---src-pages-betaling-geslaagd-nl-tsx" */),
  "component---src-pages-betaling-mislukt-en-tsx": () => import("./../../../src/pages/betaling-mislukt.en.tsx" /* webpackChunkName: "component---src-pages-betaling-mislukt-en-tsx" */),
  "component---src-pages-betaling-mislukt-nl-tsx": () => import("./../../../src/pages/betaling-mislukt.nl.tsx" /* webpackChunkName: "component---src-pages-betaling-mislukt-nl-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-nl-tsx": () => import("./../../../src/pages/contact.nl.tsx" /* webpackChunkName: "component---src-pages-contact-nl-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-nl-tsx": () => import("./../../../src/pages/index.nl.tsx" /* webpackChunkName: "component---src-pages-index-nl-tsx" */),
  "component---src-pages-informatie-en-tsx": () => import("./../../../src/pages/informatie.en.tsx" /* webpackChunkName: "component---src-pages-informatie-en-tsx" */),
  "component---src-pages-informatie-nl-tsx": () => import("./../../../src/pages/informatie.nl.tsx" /* webpackChunkName: "component---src-pages-informatie-nl-tsx" */),
  "component---src-pages-login-en-tsx": () => import("./../../../src/pages/login.en.tsx" /* webpackChunkName: "component---src-pages-login-en-tsx" */),
  "component---src-pages-login-nl-tsx": () => import("./../../../src/pages/login.nl.tsx" /* webpackChunkName: "component---src-pages-login-nl-tsx" */),
  "component---src-pages-registreren-en-tsx": () => import("./../../../src/pages/registreren.en.tsx" /* webpackChunkName: "component---src-pages-registreren-en-tsx" */),
  "component---src-pages-registreren-nl-tsx": () => import("./../../../src/pages/registreren.nl.tsx" /* webpackChunkName: "component---src-pages-registreren-nl-tsx" */),
  "component---src-pages-winkelmand-en-tsx": () => import("./../../../src/pages/winkelmand.en.tsx" /* webpackChunkName: "component---src-pages-winkelmand-en-tsx" */),
  "component---src-pages-winkelmand-nl-tsx": () => import("./../../../src/pages/winkelmand.nl.tsx" /* webpackChunkName: "component---src-pages-winkelmand-nl-tsx" */),
  "component---src-templates-product-category-product-en-tsx": () => import("./../../../src/templates/{product-category}-{product}.en.tsx" /* webpackChunkName: "component---src-templates-product-category-product-en-tsx" */),
  "component---src-templates-product-category-product-nl-tsx": () => import("./../../../src/templates/{product-category}-{product}.nl.tsx" /* webpackChunkName: "component---src-templates-product-category-product-nl-tsx" */),
  "component---src-templates-{product-category}-en-tsx": () => import("./../../../src/templates/{product-category}.en.tsx" /* webpackChunkName: "component---src-templates-{product-category}-en-tsx" */),
  "component---src-templates-{product-category}-nl-tsx": () => import("./../../../src/templates/{product-category}.nl.tsx" /* webpackChunkName: "component---src-templates-{product-category}-nl-tsx" */)
}

