import React, { ReactNode } from "react";
import styled from "styled-components";
import BaseLink from "./BaseLink";

const Link = styled(BaseLink)`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 1px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 20px;
    line-height: 32px;
  }
`;

interface NavigationLinkProps {
  children: ReactNode;
  ariaLabel?: string;
  to?: string;
  href?: string;
  className?: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  className,
  ariaLabel,
  children,
  to,
  href,
}) => (
  <Link ariaLabel={ariaLabel} to={to} href={href} className={className}>
    {children}
  </Link>
);

export default NavigationLink;
