import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { PrimaryButton } from "./Buttons";
import useCartStore from "../stores/useCartStore";
import ToteIcon from "../svg/tote.svg";

import getCurrentLocale from "../utils/getCurrentLocale";

const CartLinkContainer = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: unset;
  }
`;

const CartButton = styled(PrimaryButton)`
  display: grid;
  grid-auto-flow: column;
  gap: 0 0.5rem;

  svg {
    width: 20px;
  }
`;

const CartHeaderButton: React.FC = () => {
  const isEnglish = getCurrentLocale() === "en";
  const cartProducts = useCartStore((state) => state.cartProducts);
  const calculateAmountOfProductsInCart = cartProducts.reduce(
    (a, b) => a + (b.quantity || 0),
    0
  );

  return (
    <CartLinkContainer>
      <Link to={isEnglish ? "/en/winkelmand/" : "/winkelmand/"}>
        <CartButton>
          <ToteIcon />
          {calculateAmountOfProductsInCart}
        </CartButton>
      </Link>
    </CartLinkContainer>
  );
};

export default CartHeaderButton;
